
@import url('https://fonts.googleapis.com/css?family=Istok+Web:400,700&subset=latin-ext');
html, body{
    background: #f0f0f0 !important;
}
*{
    font-family: "Istok Web", Roboto !important;
}
#root{
    min-width: 300px;
}

.btn-callus {
    position: fixed;
    bottom:20px;
    right: 20px;
}
footer.main{
    position: relative;
    left: 16px;
    width: calc(100% - 32px);
}
.header-bar{
    background: #fff;
    height: 70px;
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
}
.box-profile{
    position: absolute;
    left: 20px;
    border-right: none;
}
.name-enterprise{
    font-size: 0.7rem;
    margin-top: -6px; 
}
.photo-user{
    width: 40px;
    height: 40px;
    float: left;
}
.icon-notify{
    opacity: 1 !important;
}
.dropdown-menu *{
    text-transform: none !important;
}
.menu-side.menu-closed{
    display: none;
}
.menu-side{
    display: block;
    position: fixed;
    z-index: 999;
    top: 0;
    background: #FFFBF7;
    width: 100%;
    height: 100%;
}
.item-menu-main{
    padding: 10px;
    margin-left: 5%;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    color: #444;
    display: block;

}
.item-menu-main:hover{
    background: #f0f0f0;
    color: #444;
    text-decoration: none !important;

    
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.item-menu-main.active{
    /*background: #1c72bc;*/
    color: #fff;
    text-decoration: none !important;

    
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.aside-menu{
    position: fixed;
    z-index: 999;
}
.item-menu-logout{
    position: relative;
    bottom: inherit
}

/********** MENU PRINCIPAL **********/
.list-items-menu .item-menu{
    display: block;
    width: 100%;
    padding: 10px 7px;
    font-size: 1rem;
    font-weight: 500;
}
.list-items-menu .item-menu.active{
    background-color:#FA5798;
    color: #fff;
}
.list-items-menu .item-menu span{
    margin-left: 10px;
}
/********** MENU PRINCIPAL **********/

/********** TOPBAR **********/
.btn-top-bar{
    border: none !important;
    border-radius: 0 !important;
    background: #fff;
    color:#1c72bc;
    font-size: 1rem !important;
    height: 70px;
    display: block !important;
    padding: 0 1.25rem;
    line-height: 70px;
    text-align: center !important;
    position: relative;


    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.btn-top-bar.active{
    background-color:#0a3563;
    color: #fff !important;
    text-decoration: none;
}
.btn-top-bar:hover{
    background-color:#1c72bc;
    color: #fff !important;
    text-decoration: none;
}
.btn-top-bar .badge{
    position: absolute;
    font-size: 0.9rem;
    top: 5px;
    left: 5px;
}
a.btn-top-bar.menu-top {
    font-size: 0.9rem !important;
    padding: 0 1.25em;
}
.text-lg{
    font-size: 1.3rem;
}
/********** TOPBAR **********/


/********** TEXT **********/
.text-color-secondary{
    color:#1c72bc !important;
}
.text-color-primary{
    color:#444 !important;
}
.text-lg{
    font-size: 1.3rem;
}
/********** TEXT **********/

.iziToast-buttons{
    width: 100% !important;
    text-align: left !important;
}

/********** BADGE **********/
.badge-pink{
    background-color: #02a5de;
    color: #fff;
}
.bg-pink{
    background-color: #02a5de;
    color: #fff;
}
.border-pink{
    border:1px solid #02a5de !important;
}
/********** BADGE **********/


/********** SELECT **********/
.box-select{
    margin-left: -5px;
    margin-right: -5px;
}
.field-select{
    position: relative;
    background-color: #fff;
}
.field-select select{
    background: transparent;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    color: #151829;
    padding-left: 1rem;
    padding-right: 40px;
    border: 1px solid rgba(0,0,0,0.1) !important;

    font-size: 0.9rem !important;
    font-weight: 700;
    width: 100%;
    line-height: 43.5px;
    height: 43.5px;
    z-index: 1;
}
.field-select select:disabled {
    color:#d0d0d0;
}
.field-select .icon-select{
    margin-left: 0px;
    position: absolute;
    right: 20px;
}
.field-select .icon-select{
    margin-left: 0px;
    position: absolute;
    right: 20px;
}

.field-select select.select-lg{
    padding-left: 1.3rem;
    padding-right: 50px;
    font-size: 1rem !important;
    font-weight: 700;
    width: 100%;
    line-height: 42px;
    height: 46px;
}
.field-select.select-lg .icon-select{
    margin-left: 0px;
    position: absolute;
    right: 20px;
    z-index: 0;
}

/********** SELECT **********/

/********** CARD **********/

/********** CARD **********/
.title-card{
    font-weight: 600;
    border-left: 8px solid;
    margin-left: calc(-1.3em + 10px);
    padding-left: 12px;
    height: 50px;
    line-height: 50px;
}
/*********** CARD **********/

/*CONTEÚDO PRINCIPAL*/
main{
    position: relative;
    left: 0px;
}
.title-page{
    font-size: 1.5rem;
    font-weight: bold;
}
.hidden {
    display: none !important;
}

/*MODAL*/
.bg-dialog{
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    display: block;
}
.dialog-body{
    background-color: #fff;
}
.bg-dialog.hidden{
    display: none !important;
}
.text-dialog{
    font-size: 1.3rem;
}
.main-client, .main-admin{
    margin-top: 90px;
    z-index: 0;
}
.dialog-body.allScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
}

.dropdown-menu{
    z-index: 999;
}
/**TIMELINE**/
.item-timeline{
    height: 50px;
    line-height: 25px;
    border-radius: 25px;
    padding: 0;
}
.item-timeline small{
    line-height: 15px;
    display: block;
}
.item-timeline .icon{
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    line-height: 48px;
}



.btn-close-menu{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 2px solid #9a0202;
    background: #d00404;
    color: #fff;
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.btn-open-menu *{
    margin-top: 17px;
}
#box-menu-top{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;


    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
#box-menu-top.menu-closed{
    display: none;
}
.btn-top-bar.menu-top{
    text-align: left !important;
}

@media screen and (min-width: 768px) {
    
}

@media screen and (min-width: 992px) {
    .btn-top-bar.btn-open-menu{
        display: none !important;
    }
    .btn-close-menu{
        display: none;
    }
    #box-menu-top, #box-menu-top.menu-closed{
        position: static;
        display: flex;
        width: auto;
    }

    /*CONTEÚDO PRINCIPAL*/
    main{
        position: relative;
        margin-bottom: 60px;
        width: auto;
    }
    footer.main{
        position: relative;
        bottom: 20px;
    }
    .header-bar{
        z-index: 1;
    }

}

@media screen and (min-width: 1200px) {
    
}