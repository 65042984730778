@import 'libs/bootstrap.min.css';
body, .content-login {
    background-color: transparent;
}
html {
    background-color: #f5f5f5;
    height: 100%;
}
.btn-tab-login{
    position: relative;
    border: 0;
    background: #f0f0f0;
    color: #999;
    font-weight: 100;
    font-size: 1.1rem;
    outline: none;
    top: 1px;
}
.btn-tab-login.active{
    font-weight: bold;
    color: #333;
    font-size: 1.15;
    background: #fff;
}
.btn-tab-login:focus{
    outline: none;
    border:  0;
}
.react-datepicker-wrapper, .react-datepicker__input-container{
    width: 100%;
}
.box-login{
    position: relative;
    z-index: 1;
}